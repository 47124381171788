<template>
  <v-container fluid>

  <CustomerUsersSectionSearch @search="() => $refs.table.refresh()" />

  <BaseTable
    ref="table"
    title="Utenti"
    :headers="headers"
    :actions="actions"
    :elevation="0"
    :exportable="canUser('customers.users', 'download')"
    module="customersUsers"
  >
    <template v-slot:item.code="{ item }">
      <v-chip label>{{ item.code | placeholder('N/A') }}</v-chip>
    </template>
    <template v-slot:actions>
      <v-btn
        v-if="canUser('customers.users', 'create')"
        color="green"
        dark
        tile
        depressed
        @click="openModal"
      >
        Aggiungi
      </v-btn>
    </template>
    <template v-slot:footer>
      <v-dialog
        v-model="dialog"
        max-width="75%"
        :fullscreen="$vuetify.breakpoint.mobile"
        :retain-focus="false"
        @click:outside="modalClose"
      >
        <v-card>
          <v-card-title>Aggiungi Utente</v-card-title>
          <v-card-text>
            <CustomerUsersForm
              v-if="dialog"
              :edit="editMode"
              :customer-not-selected="true"
              @submitted="onSubmitted"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </BaseTable>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import { authComputed } from '@state/helpers.js'
import CustomerUsersForm from '@components/customer_users/CustomerUsersForm.vue'
import CustomerUsersSectionSearch from '@components/customer_users/CustomerUsersSectionSearch.vue'

export default {
  name: 'CustomerUsers',
  components: { CustomerUsersForm, CustomerUsersSectionSearch, BaseTable },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: '#ID',
          value: 'code',
        },
        {
          text: 'Cognome',
          value: 'last_name',
        },
        {
          text: 'Nome',
          value: 'first_name',
        },
        {
          text: 'Ruolo',
          value: 'role',
        },
        {
          text: 'E-Mail',
          value: 'email',
        },
        {
          text: 'Telefono',
          value: 'mobile_phone_number',
        },
        {
          text: 'Sede',
          value: 'customers_location.name',
        },
        {
          text: 'Gruppo',
          value: 'customers_users_group.name',
        },
        {
          text: 'Cliente',
          value: 'customer.common_name',
        },
        {
          text: 'Inserito il',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () => this.canUser('customers.users', 'update'),
          handler: this.openModal,
        },
        {
          label: 'Elimina',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: () => this.canUser('customers.users', 'delete'),
          handler: this.remove,
        },
      ],
    }
  },
  computed: {
    ...authComputed
  },
  mounted() {
    this.setScopes(['group', 'location', 'platformuser', 'customer'])
    this.customer_id = this.customer.id
  },
  beforeDestroy() {
    this.setScopes([])
    this.resetFilters()
  },

  methods: {
    ...mapActions('customersUsers', ['removeItem']),
    ...mapMutations('customersUsers', {
      setScopes: 'SET_SCOPES',
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),
    async remove(item) {
      const res = await this.$dialog.confirm({
        text:
          "Proseguire con l'eliminazione dell'utente ?\n l'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      await this.$refs.table.refresh()
    },
    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
