<template>
  <FormItem
    v-model="id"
    type="select"
    :values="list"
    name="Gruppo"
    :rules="rules"
    :hint="hint"
    persistent-hint
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    :dense="dense"
    :disabled="disabled"
  />
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CustomersUsersGroupSelect',
  components: {
    FormItem,
  },
  props: {
    customerid: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    cols: {
      type: Number,
      default: 6,
    },
    sm: {
      type: Number,
      default: null,
    },
    md: {
      type: Number,
      default: 3,
    },
    lg: {
      type: Number,
      default: null,
    },
    xl: {
      type: Number,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    autoSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      },
    },
    ...mapState('customersUsersGroups', ['list']),
  },
  watch: {
    customerid: {
      immediate: true,
      handler: 'populate',
    },
    list: {
      immediate: true,
      handler: 'onMounted',
    },
  },
  mounted() {
    this.populate()
  },
  methods: {
    ...mapActions('customersUsersGroups', ['getDropdownList']),
    populate() {
      if (this.customerid) {
        this.getDropdownList({
          filters: {
            customer_id: this.customerid,
          },
        })
      }
    },
    onMounted() {
      if (this.autoSelect && this.list.length === 1) {
        this.id = this.list[0].value
      }
    },
  },
}
</script>
