<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <v-row>
      <FormItem
        v-model="last_name"
        name="Cognome"
        :cols="4"
        :rules="{ required: !file }"
        :disabled="!!file"
      />
      <FormItem
        v-model="first_name"
        name="Nome"
        :cols="4"
        :rules="{ required: !file }"
        :disabled="!!file"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="email"
        name="Email"
        type="email"
        :cols="4"
        :sm="4"
        :md="3"
        :lg="4"
        :rules="{
          required: isEmailRequired,
          email: true,
        }"
        :disabled="!!file"
      />
      <FormItem
        v-model="mobile_phone_number"
        :rules="{
          required: isPhoneRequired,
          phone: true,
        }"
        name="Cellulare"
        :cols="4"
        :sm="4"
        :md="4"
        :lg="3"
        :disabled="!!file"
      />
      <FormItem
        v-model="work_phone_number"
        rules="phone"
        name="Telefono"
        :cols="4"
        :sm="4"
        :md="4"
        :lg="3"
        :disabled="!!file"
      />
    </v-row>

    <v-row>
      <FormCustomers
        v-if="customerNotSelected && !edit"
        v-model="customer_id"
        :customer_id="customer_id"
        clearable
        :rules="{ required: !file }"
        name="Seleziona Cliente"
        hint="Inserisci ragione sociale, codice o P.IVA"
        persistent-hint
        :sm="12"
        :md="4"
        :lg="4"
        :xl="4"
      />
      <CustomersSubscriptionsSelect
        v-if="!edit"
        v-model="plans_subscription_id"
        :customerid="customer_id"
        name="Sottoscrizione"
        :rules="{ required: !file }"
        :disabled="!!file"
      />

      <CustomersLocationsSelect
        v-model="customers_location_id"
        :customerid="customer_id"
        name="Sede"
        clearable
        :disabled="!!file"
      />

      <CustomersUsersGroupSelect
        v-model="customers_users_group_id"
        :customerid="customer_id"
        name="Gruppo"
        clearable
        :disabled="!!file"
      />
    </v-row>

    <v-row v-if="!edit || !platform_user">
      <FormItem
        v-model="create_login"
        type="switch"
        name="Crea Utenze Portale"
        :cols="2"
        :disabled="!!file"
      />
      <RolesSelect
        v-model="role_id"
        filter="customer"
        :cols="3"
        :disabled="!create_login && !!file"
      />
    </v-row>

    <v-slide-y-transition>
      <v-row v-if="!customers_location_id">
        <v-col cols="12">
          <h4>Recapito alternativo</h4>
          <v-divider />
        </v-col>
        <FormAddress
          v-if="!edit"
          v-model="address"
          name="Indirizzo"
          :cols="12"
          :md="8"
          :lg="5"
          :xl="5"
          dense
          :rules="{ required: !file }"
          :disabled="!!file"
          @address="setCurrentValues"
        />
        <FormItem
          v-else
          v-model="address"
          name="Indirizzo"
          :cols="12"
          :md="8"
          :lg="5"
          :xl="5"
          dense
          :rules="{ required: !file }"
          :disabled="!!file"
        />

        <FormItem
          v-model="city"
          name="Città"
          :cols="4"
          :md="3"
          dense
          :rules="{ required: !file }"
          :disabled="!!file"
        />
        <FormItem
          v-model="cap"
          name="CAP"
          :cols="1"
          :md="1"
          dense
          :rules="{ required: !file }"
          :disabled="!!file"
        />
        <FormRegions
          v-model="region"
          :cols="3"
          :md="3"
          :lg="3"
          :xl="3"
          :rules="{ required: !file }"
          :disabled="!!file"
          dense
        />
        <FormProvince
          v-model="province"
          :regions="region"
          :cols="3"
          :md="3"
          :lg="3"
          :xl="3"
          :rules="{ required: !file }"
          :disabled="!!file"
          dense
        />
      </v-row>
    </v-slide-y-transition>
    <v-divider class="my-6" />
    <v-row v-if="!edit">
      <v-col>
        <p>
          E' possibile caricare massivamente più utenti procedendo al
          caricamento multiplo.
          <br />
          <a href="/static/templates/users_import_template.xlsx">Scarica</a> il
          template personalizzato e caricalo dopo aver riempito i campi
          necessari.
        </p>
      </v-col>
      <v-col cols="5">
        <v-file-input
          v-model="file"
          label="Import massivo XLS"
          accept="application/vnd.ms-excel || application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          rule="size:100"
          show-size
          filled
          clearable
        ></v-file-input>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormRegions from '@components/forms/FormRegions.vue'
import FormProvince from '@components/forms/FormProvince.vue'
import FormAddress from '@components/forms/FormAddress.vue'
import CustomersLocationsSelect from '@/src/components/customer_locations/CustomersLocationsSelect.vue'
import CustomersSubscriptionsSelect from '@components/customer_plans_subscriptions/CustomersSubscriptionsSelect.vue'
import CustomersUsersGroupSelect from '@components/customer_users_groups/CustomersUsersGroupSelect.vue'
import RolesSelect from '@components/admin/roles/RolesSelect.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'

const { mapFields } = createHelpers({
  getterType: 'customersUsers/getCurrent',
  mutationType: 'customersUsers/SET_CURRENT_FIELDS',
})

export default {
  name: 'CustomerUsersForm',
  components: {
    RolesSelect,
    CustomersUsersGroupSelect,
    CustomersSubscriptionsSelect,
    CustomersLocationsSelect,
    FormAddress,
    FormProvince,
    FormRegions,
    FormItem,
    FormCustomers,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    customerNotSelected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  computed: {
    ...mapFields([
      'address_2',
      'address',
      'alt_phone_number_2',
      'alt_phone_number',
      'cap',
      'city',
      'country',
      'create_login',
      'customer_id',
      'customers_location_id',
      'customers_users_group_id',
      'email',
      'file',
      'first_name',
      'last_name',
      'mobile_phone_number',
      'plans_subscription_id',
      'platform_user',
      'province',
      'region',
      'role_id',
      'role',
      'work_phone_number',
    ]),
    ...mapState('customersUsers', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
    }),
    isEmailRequired() {
      const isLinkedToUser = this.create_login || this.platform_user
      const hasMobile = this.mobile_phone_number
      const shouldRequire = isLinkedToUser && !hasMobile
      return !!(!this.file && shouldRequire)
    },
    isPhoneRequired() {
      const isLinkedToUser = this.create_login || this.platform_user
      const hasEmail = this.email
      const shouldRequire = isLinkedToUser && !hasEmail
      return !!(!this.file && shouldRequire)
    },
  },
  watch: {
    /* Every time the customer changes reset plans_subscription_id and customers_location_id */
    customer_id() {
      this.plans_subscription_id = null
      this.customers_location_id = null
    },
  },
  methods: {
    ...mapMutations('customersUsers', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('customersUsers', ['create', 'update', 'createBulk']),
    async onSubmit() {
      this.$emit('submit')
      try {
        if (this.file) {
          await this.createBulk()
          this.$emit('submitted')
          this.reset()
        } else {
          if (!this.edit) {
            await this.create()
          } else {
            await this.update()
          }

          this.$emit('submitted', this.id)
        }
      } catch (err) {
        console.debug(err)
      }
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
