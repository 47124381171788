<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="code"
        type="text"
        name="Codice"
        hint="Ricerca per Codice"
        dense
        :sm="6"
        :md="3"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="username"
        type="text"
        name="Nome Utente"
        hint="Ricerca Nome Utente"
        dense
        :sm="6"
        :md="3"
        :lg="3"
        :xl="3"
      />

      <FormItem
        v-model="email"
        type="text"
        name="Email"
        hint="Ricerca per email"
        dense
        :sm="6"
        :md="3"
        :lg="3"
        :xl="3"
      />

      <FormItem
        v-model="last_name"
        type="text"
        name="Cognome"
        hint="Ricerca per Cognome"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />

      <RolesSelect
        v-model="role_id"
        filter="customer"
        clearable
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />

      <FormCustomers
        v-model="customer_id"
        name="Cliente"
        hint="Ricerca per Cliente"
        clearable
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <CustomersSubscriptionsSelect
        v-model="plans_subscription_id"
        :customerid="customer_id"
        :disabled="!customer_id"
        name="Sottoscrizione"
        clearable
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <CustomersLocationsSelect
        v-model="customers_location_id"
        :customerid="customer_id"
        :disabled="!customer_id"
        name="Sede"
        clearable
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import RolesSelect from '@components/admin/roles/RolesSelect.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import CustomersLocationsSelect from '@/src/components/customer_locations/CustomersLocationsSelect.vue'
import CustomersSubscriptionsSelect from '@components/customer_plans_subscriptions/CustomersSubscriptionsSelect.vue'

const { mapFields } = createHelpers({
  getterType: 'customersUsers/getFiltersFields',
  mutationType: 'customersUsers/SET_FILTER_FIELDS',
})

export default {
  name: 'CustomerUsersSectionSearch',
  components: { 
    FormItem, 
    BaseSectionSearch, 
    RolesSelect, 
    FormCustomers, 
    CustomersLocationsSelect,
    CustomersSubscriptionsSelect,
  },
  computed: {
    ...mapFields([
      'code',
      'email',
      'last_name',
      'role_id',
      'username',
      'customer_id',
      'plans_subscription_id',
      'customers_location_id',
    ]),
    ...mapState('customersUsers', ['filters', 'types']),
  },
  watch: {
    /* Every time the customer changes reset plans_subscription_id and customers_location_id */
    customer_id() {
      this.plans_subscription_id = null
      this.customers_location_id = null
    },
  },
  mounted() {
    this.reset()
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapMutations('customersUsers', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
